import React from 'react';
import PropTypes from 'prop-types';

import { configureBEMClasses } from '../../../constants/functions';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function Error(props) {
    return (
        <div className={classes['not-found']}>
            <h1 className={classes['not-found__heading']}>{props.statusCode} {props.message}</h1>
        </div>
    );
}

Error.propTypes = {
    statusCode: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};

export default Error;