export function deepCopy(objectToCopy) {
    let newObject = {};

    if (typeof objectToCopy !== "object" || objectToCopy === null) {
        return objectToCopy; // Return the value if objectToCopy is not an object
    }

    // Create an array or object to hold the values
    newObject = Array.isArray(objectToCopy) ? [] : {};

    for (const key in objectToCopy) {
        const value = objectToCopy[key];

        // Recursively (deep) copy for nested objects, including arrays
        newObject[key] = deepCopy(value);
    }

    return newObject;
}

export const configureBEMClasses = (styles) => {
    const cssModuleHashLength = 7;
    const cssModuleSeparetor = '--';
    const copiedStyles = deepCopy(styles);

    const classes = {};

    for (const style in copiedStyles) {
        const styleValue = styles[style];

        const firstIndexOfDoubleDash = styleValue.indexOf(cssModuleSeparetor);
        const indexOfSuffixAddFromModules = styleValue.length - cssModuleHashLength;

        const transformedStyle = styleValue
            .substring(firstIndexOfDoubleDash + cssModuleSeparetor.length, indexOfSuffixAddFromModules);

        classes[transformedStyle] = copiedStyles[style];
    };

    return classes;
}