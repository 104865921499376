import React from 'react';
//import PropTypes from 'prop-types';

import Error from '../../ui/error';

function RealNotFoundPage(props) {
  return (
    <Error statusCode="404" message="Page Not Found"/>
  );
}

RealNotFoundPage.propTypes = {

};

export default RealNotFoundPage;