import React from 'react';
//import PropTypes from 'prop-types';

import RealNotFoundPage from '../components/real-pages/404-not-found';

function NotFoundPage(props) {
  return (<RealNotFoundPage />);
}

NotFoundPage.propTypes = {

};

export default NotFoundPage;